html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font: 300 11px/1.4 'Helvetica Neue', 'sans-serif';
  /*color: #444; */
  color: #3e6d9d;
  background: #abbdcf;
  /*background: #424855; */
  /* background: #5f6b76; */
  /* background: #022c43; */
  /* background: #181818; */
  /* background: #0082ff; */
  overflow: hidden;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
