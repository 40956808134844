@media only screen and (min-width: 650px) {
    .text-animate {
        display: inline-block;
        opacity: 0;
        animation: fadeIn 1s 1s;
        animation-fill-mode: forwards;
        min-width: 10px;
    }

    .text-animate:hover {
        //color:#cc8660;
        color:#3e6d9d;
    }

    @for $i from 15 through 35 {
        .text-animate._#{$i} {
            animation-delay: #{calc($i / 10)}s;
            // text-shadow: 1px 1px #cc8660;
            color:#325980;
            font-size: 9vw;

            &:hover {
                //color: #cc8660;
                color:#3e6d9d;
            }
        }
    }

    .text-animate2 {
        display: inline-block;
        opacity: 0;
        animation: fadeIn 1s 1s;
        animation-fill-mode: forwards;
        min-width: 10px;
    }

    .text-animate2:hover {
        //color:#cc8660;
        color:#3e6d9d;
    }

    @for $i from 15 through 35 {
        .text-animate2._#{$i} {
            animation-delay: #{calc($i / 10)}s;
            // text-shadow: 1px 1px #cc8660;
            color:#325980;
            font-size: 100px;

            &:hover {
                //color: #cc8660;
                color:#3e6d9d;
            }
        }
    }

    .text-animate3 {
        display: inline-block;
        opacity: 0;
        animation: fadeIn 1s 1s;
        animation-fill-mode: forwards;
        min-width: 10px;
    }

    .text-animate3:hover {
        //color:#cc8660;
        color:#3e6d9d;
    }

    @for $i from 15 through 35 {
        .text-animate3._#{$i} {
            animation-delay: #{calc($i / 10)}s;
            // text-shadow: 1px 1px #cc8660;
            color:#325980;
            font-size: 7vw;

            &:hover {
                //color: #cc8660;
                color:#3e6d9d;
            }
        }
    }

    .text-animate4 {
        display: inline-block;
        opacity: 0;
        animation: fadeIn 1s 1s;
        animation-fill-mode: forwards;
        min-width: 10px;
    }

    .text-animate5 {
        display: inline-block;
        opacity: 0;
        animation: fadeIn 1s 1s;
        animation-fill-mode: forwards;
        min-width: 10px;
    }

    .text-animate5:hover {
        //color:#cc8660;
        color:#3e6d9d;
    }

    @for $i from 15 through 35 {
        .text-animate5._#{$i} {
            animation-delay: #{calc($i / 10)}s;
            // text-shadow: 1px 1px #cc8660;
            color:#325980;
            // font-size: 82px;
            font-size: 8vw;

            &:hover {
                //color: #cc8660;
                color:#3e6d9d;
            }
        }
    }
}

@media only screen and (max-width: 650px) {
    .text-animate {
        display: inline-block;
        opacity: 0;
        animation: fadeIn 1s 1s;
        animation-fill-mode: forwards;
        min-width: 10px;
    }

    .text-animate:hover {
        //color:#cc8660;
        color:#3e6d9d;
    }

    @for $i from 15 through 28 {
        .text-animate._#{$i} {
            animation-delay: #{calc($i / 10)}s;
            // text-shadow: 1px 1px #cc8660;
            color:#325980;
            font-size: 12vw;

            &:hover {
                //color: #cc8660;
                color:#3e6d9d;
            }
        }
    }


    .text-animate2 {
        display: inline-block;
        opacity: 0;
        animation: fadeIn 1s 1s;
        animation-fill-mode: forwards;
        min-width: 10px;
    }

    .text-animate2:hover {
        //color:#cc8660;
        color:#3e6d9d;
    }

    @for $i from 15 through 35 {
        .text-animate2._#{$i} {
            animation-delay: #{calc($i / 10)}s;
            // text-shadow: 1px 1px #cc8660;
            //color:#325980;
            color:#3e6d9d;
            font-size: 70px;

            &:hover {
                //color: #cc8660;
                color:#3e6d9d;
            }
        }
    }

    .text-animate3 {
        display: inline-block;
        opacity: 0;
        animation: fadeIn 1s 1s;
        animation-fill-mode: forwards;
        min-width: 10px;
    }

    .text-animate3:hover {
        //color:#cc8660;
        color:#3e6d9d;
    }

    @for $i from 15 through 35 {
        .text-animate3._#{$i} {
            animation-delay: #{calc($i / 10)}s;
            // text-shadow: 1px 1px #cc8660;
            color:#325980;
            font-size: 50px;

            &:hover {
                //color: #cc8660;
                color:#3e6d9d;
            }
        }
    }

    .text-animate5 {
        display: inline-block;
        opacity: 0;
        animation: fadeIn 1s 1s;
        animation-fill-mode: forwards;
        min-width: 10px;
    }

    .text-animate5:hover {
        //color:#cc8660;
        color:#3e6d9d;
    }

    @for $i from 15 through 35 {
        .text-animate5._#{$i} {
            animation-delay: #{calc($i / 10)}s;
            // text-shadow: 1px 1px #cc8660;
            color:#325980;
            font-size: 65px;

            &:hover {
                //color: #cc8660;
                color:#3e6d9d;
            }
        }
    }
}
