@media only screen and (min-width: 650px) {
    .logo-container {
        position: absolute;
        left: 60%;
        transform: translateY(-15%);
        width: 40%;
        // max-height: 90%;
    }

    .solid-logo {
        width: 100%;
        //animation: color-change 20s infinite;

    }

    @keyframes color-change {
        0% { filter: hue-rotate(0deg); }
        5% { filter: hue-rotate(0deg); }
        10% { filter: hue-rotate(0deg); }
        15% { filter: hue-rotate(0deg); }
        20% { filter: hue-rotate(0deg); }
        25% { filter: hue-rotate(0deg); }
        30% { filter: hue-rotate(24deg); }
        35% { filter: hue-rotate(48deg); }
        40% { filter: hue-rotate(72deg); }
        45% { filter: hue-rotate(96deg); }
        50% { filter: hue-rotate(120deg); }
        55% { filter: hue-rotate(144deg); }
        60% { filter: hue-rotate(168deg); }
        65% { filter: hue-rotate(192deg); }
        70% { filter: hue-rotate(216deg); }
        75% { filter: hue-rotate(240deg); }
        80% { filter: hue-rotate(264deg); }
        85% { filter: hue-rotate(288deg); }
        90% { filter: hue-rotate(312deg); }
        95% { filter: hue-rotate(336deg); }
        100% { filter: hue-rotate(360deg); }
      }
}

@media only screen and (max-width: 650px) {
    .logo-container {
        position: relative;
        top: 450px;
        transform: translateY(-50%);
        width: 100%;
        text-align: center;
        padding-bottom: 50px;
    }

    .solid-logo {
        width: 40%;
        //animation: color-change 20s infinite;

    }

    @keyframes color-change {
        0% { filter: hue-rotate(0deg); }
        5% { filter: hue-rotate(0deg); }
        10% { filter: hue-rotate(0deg); }
        15% { filter: hue-rotate(0deg); }
        20% { filter: hue-rotate(0deg); }
        25% { filter: hue-rotate(0deg); }
        30% { filter: hue-rotate(24deg); }
        35% { filter: hue-rotate(48deg); }
        40% { filter: hue-rotate(72deg); }
        45% { filter: hue-rotate(96deg); }
        50% { filter: hue-rotate(120deg); }
        55% { filter: hue-rotate(144deg); }
        60% { filter: hue-rotate(168deg); }
        65% { filter: hue-rotate(192deg); }
        70% { filter: hue-rotate(216deg); }
        75% { filter: hue-rotate(240deg); }
        80% { filter: hue-rotate(264deg); }
        85% { filter: hue-rotate(288deg); }
        90% { filter: hue-rotate(312deg); }
        95% { filter: hue-rotate(336deg); }
        100% { filter: hue-rotate(360deg); }
      }
}
