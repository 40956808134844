@media only screen and (min-width: 650px) {
    .home-page {
        .text-zone {
            position: absolute;
            left: 8%;
            width: 50%;
            transform: translateY(-3%);
        }

        h1 {
            color: #4d4d4e;
            margin: 0;
            font-size: 6vw;
            font-family: 'Coolvetica';
            font-weight: 400;
        }

        h2 {
            color: #4d4d4e;
            margin-top: 20px;
            font-weight: 400;
            font-size: 2vw;
            font-family: sans-serif;
            letter-spacing: 2px;
            animation: fadeIn 1s 2.5s backwards;
        }

        a {
            font-size:50px;
            font-weight: 400;
            display: inline-block;
            position: relative;
            text-decoration: none;
            margin-top:8px;
            padding-bottom:70px;
            margin-right:7%;
            width: 64px;
            color:#4d4d4e;
            animation: fadeIn 1s 2.5s backwards;

            &:nth-of-type(4) {
                margin-right: -1%;
            }

            i {
                transition: all 0.3s ease-out;
            }

            &:hover {
                color:#3e6d9d;

                svg {
                    opacity: 1;
                }

                &:after {
                    opacity: 1;
                }
            }

            &:after {
                content: 'Contact';
                font-size: 15px;
                letter-spacing: 2px;
                position: relative;
                right: 10px;
                bottom: 0;
                display: block;
                width: 100%;
                text-align: left;
                opacity: 0;
                transition: all 0.3s ease-out;
            }
        }

        a.github-link {
            &:after {
                content: 'Github';
                right:5px;
            }
        }

        a.linkedin-link {
            &:after {
                content: 'LinkedIn';
                right: 15px;
            }
        }

        a.resume-link {
            &:after {
                content: 'Scholar';
                right:10px;
            }
        }
    }

}

@media only screen and (max-width: 650px) {
    .home-page {
        .text-zone {
            position: absolute;
            top: 120px;
            transform: translateY(-50%);
            width: 100%;
            max-height: 90%;
            text-align: center;
            align-items: center;

            h1 {
                color: #4d4d4e;
                margin: 0;
                font-size: 8vw;
                font-family: 'Coolvetica';
                font-weight: 400;
            }

            h2 {
                color:#4d4d4e;
                margin-top: 20px;
                font-weight: 400;
                font-size: 15px;
                font-family: sans-serif;
                letter-spacing: 2px;
                animation: fadeIn 1s 2.5s backwards;
            }

            a {
                font-size:50px;
                font-weight: 400;
                display: inline-block;
                position: relative;
                text-decoration: none;
                margin-top:8px;
                margin-right:20px;
                width: 64px;
                color:#4d4d4e;
                animation: fadeIn 1s 2.5s backwards;

                i {
                    transition: all 0.3s ease-out;
                }

                &:hover {
                    color:#3e6d9d;

                    svg {
                        opacity: 1;
                    }

                    &:after {
                        opacity: 1;
                    }
                }

                &:after {
                    content: 'Contact';
                    font-size: 15px;
                    letter-spacing: 2px;
                    position: relative;
                    right: 6px;
                    bottom: 0;
                    display: block;
                    width: 100%;
                    text-align: left;
                    opacity: 0;
                    transition: all 0.3s ease-out;
                }
            }

            a.github-link {
                &:after {
                    content: 'Github';
                    right:1px;
                }
            }

            a.linkedin-link {
                &:after {
                    content: 'LinkedIn';
                    right: 7px;
                }
            }

            a.resume-link {
                &:after {
                    content: 'Scholar';
                    right:3px;
                }
            }
        }
    }

}
